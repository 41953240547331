<template>
	<v-container>
		<div style="background-color:maroon;color:white">
			Users
		</div>
		<v-text-field
			v-model="search"
			append-icon="mdi-magnify"
			label="Search"
			single-line
			hide-details
			style="margin-bottom: 5px;"
		></v-text-field>
		<v-data-table
			v-model="selectedItem"
			:headers="headers"
			:items="users"
			item-key="_id"
			:item-class="itemRowBackground"			
			single-select
			class="elevation-1"
			@click:row="editItem"
			:search="search"
		>
		<template v-slot:item.accesslevel="{ item }">
			{{ displayAccessLevel(item.accesslevel) }}
		</template>		
		<template v-slot:item.actions="{ item }">
			<v-icon
				v-if="item._id!==currentUserID"
				small
				class="mr-2"
				@click="editItem(item)"
			>
				mdi-pencil
			</v-icon>
			<v-icon
				v-if="item._id===currentUserID"
				small
				class="mr-2"
				@click="editItem(item)"
			>
				mdi-lock
			</v-icon>
			<v-icon
				v-if="item._id!==currentUserID"
				small
				@click="deleteItem(item)"
			>
				mdi-delete
			</v-icon>
		</template>		
		</v-data-table>	
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-2"
							fab
							dark
							color="indigo"
							v-bind="attrs"
							v-on="on"
							@click="addUser"
							style="margin-top:5px"
						>
							<v-icon dark>
								mdi-plus
							</v-icon>
						</v-btn>
					</template>
					<span>Add new user</span>
				</v-tooltip>
		<v-container v-if="currentUser&&currentUser._id||newUser">				
			<div v-if="!newUser" style="background-color:maroon;color:white; margin-top:15px;">
				Details
			</div>	
			<div v-if="newUser" style="background-color:maroon;color:white; margin-top:15px;">
				Add user
			</div>	
			<div>
			<form @submit.prevent="submit">
				<v-row v-if="currentUser._id!==currentUserID&&!newUser">
					<v-switch
						v-model="currentUser.deleted"
						label="Deleted"
						style="margin-left:15px"
					></v-switch>	
				</v-row>
				<v-row>
					<v-col cols=6>
						<v-text-field
							v-model="currentUser.name"
							:counter="40"
							label="User Name"
						></v-text-field>
					</v-col>						
					<v-col cols=6>
						<v-select
							v-model="currentUser.accesslevel"
							:counter="120"
							label="Access Level"
							:items="accessDetails"
							item-text="label"
							item-value="accessLevel"
						></v-select>
					</v-col>						
				</v-row>	
				<v-row>
					<v-col v-if="currentUser._id!==currentUserID&&!newUser">
						<v-text-field
							v-model="currentUser.password"
							:counter="40"
							label="Password (only required to change password)"
							type="password"
						></v-text-field>
					</v-col>						
					<v-col v-if="newUser">
						<v-text-field
							v-model="currentUser.password"
							:counter="40"
							label="Password"
							type="password"
						></v-text-field>
					</v-col>						
				</v-row>	
			</form>
			</div>
			<v-row>
				<v-tooltip top 	v-if="currentUser._id!==currentUserID">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-2"
							fab
							dark
							:color="buttonColour"
							v-bind="attrs"
							v-on="on"
							@click="saveData"
							style="margin-top:5px"
						>
							<v-icon dark>
								mdi-content-save
							</v-icon>
						</v-btn>
					</template>
					<span>Save changes</span>
				</v-tooltip>
			</v-row>
			<div>
				<div v-if="currentUser._id===currentUserID" style="margin-top:15px">
					The current user cannot be updated please login as a different user to update the current user.  The password can be changed for the current user.
				</div>
			</div>
		</v-container>
	</v-container>
</template>

<script>
	import services from '@/services/services.js'

  export default {
    name: 'residential',
    data: () => ({
		search:'',
		users:[],
		currentUser:{},
		selectedItem:[],
		accessDetails:[{accessLevel:1,label:"User"},{accessLevel:2,label:"Administrator"}],
        headers: [
          {
            text: 'Username',
            align: 'start',
            sortable: false,
            value: 'name',
          },
			{ text: 'Access Level', value: 'accesslevel' },
			{ text: 'Actions', value: 'actions', sortable: false }
        ],	
		buttonColour:"indigo",
		newUser:false,
    }),
	computed: {
		currentUserID() {
			return this.$store.state.user;
		},
		currentToken() {
			return this.$store.state.token;
		},		
	},
	methods: {
		itemRowBackground(item) {
			return item.deleted ? 'deletedItem' : ''
		},
		displayAccessLevel: (accessLevel) => {
			var result="User";
			var accessLevels=[{accessLevel:1,label:"User"},{accessLevel:2,label:"Administrator"}];
			if (accessLevels)
			{
				accessLevels.forEach(function(item){
					if (item.accessLevel===accessLevel)
					{
						result=item.label;
					}
				});
			}
			
			return result;
		},		
		addUser()
		{
			console.log("Add User");
			this.newUser=true;
		},
		editItem(item){
			this.currentUser=item;
			this.buttonColour="indigo"
		},
		deleteItem(item){
			this.currentUser=item;
			this.currentUser.deleted=true;
		},
		updateData() 
		{
			var request={token:this.$store.state.token};
			services.getUsers(request).then(response=>{if (response.data && response.data.data) this.users=response.data.data;if (response.data.token && response.data.result==="ok") this.$store.commit('setToken',response.data.token);}).catch(error=> { console.log(error)})		
		},
		saveData()
		{
			var request={data:this.currentUser,token:this.$store.state.token,collection:"tinting-access"};
			if (this.newUser)
				request.newItem=true;
			console.log(request);
			services.updateCollectionItem(request).then(response=>{if (response.data.token && response.data.result==="ok") this.$store.commit('setToken',response.data.token); if (response.data.result==="ok") {this.currentUser={}; this.newUser=false; this.updateData()} else this.buttonColour="red";}).catch(error=> { console.log(error); this.buttonColour="red"})
		}
	},
	created() {
		var request={token:this.$store.state.token};
		services.getUsers(request).then(response=>{if (response.data && response.data.data) this.users=response.data.data;if (response.data.token && response.data.result==="ok") this.$store.commit('setToken',response.data.token);}).catch(error=> { console.log(error)})
	},
  }
</script>
<style>
	.deletedItem {
		text-decoration: line-through;
	}
</style>
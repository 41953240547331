var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticStyle:{"background-color":"maroon","color":"white"}},[_vm._v(" Quotes ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{staticStyle:{"margin-bottom":"5px"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs1 = ref.attrs1;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"indigo"},on:{"click":function($event){$event.stopPropagation();_vm.filterDialog = true}}},'v-btn',attrs1,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-filter ")])],1)]}}])},[_c('span',[_vm._v("Database Filter")])]),(_vm.filterCriteria!=='')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"indigo"},on:{"click":function($event){$event.stopPropagation();return _vm.resetFilter.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cancel ")])],1)]}}],null,false,3213219981)},[_c('span',[_vm._v("Clear Filter")])]):_vm._e()],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":15,"item-key":"_id","single-select":"","item-class":_vm.itemRowBackground,"search":_vm.search},on:{"click:row":_vm.selectedItem},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDisplayDate(item.date))+" ")]}}]),model:{value:(_vm.dataItem),callback:function ($$v) {_vm.dataItem=$$v},expression:"dataItem"}}),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600px","persistent":""},model:{value:(_vm.filterDialog),callback:function ($$v) {_vm.filterDialog=$$v},expression:"filterDialog"}},[[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Database Filter")]),_c('v-container',[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('v-text-field',{attrs:{"label":"Word search"},model:{value:(_vm.filterCriteria),callback:function ($$v) {_vm.filterCriteria=$$v},expression:"filterCriteria"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"indigo","type":"submit"},on:{"click":_vm.applyFilter}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-magnify-plus ")])],1)]}}])},[_c('span',[_vm._v("Search")])])],1)]),_c('v-toolbar',{attrs:{"dark":""},on:{"click":function($event){_vm.filterDialog = false}}},[_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.filterDialog = false}}},[_vm._v("Close")])],1)],1)],1)]],2),(false)?_c('div',[_c('div',{staticStyle:{"background-color":"maroon","color":"white","margin-top":"20px"}},[_vm._v(" Customer ")]),_c('v-row',{staticStyle:{"margin":"0px"}},[(_vm.customerRecord.firstName)?_c('v-col',{attrs:{"cols":"5"}},[_c('strong',[_vm._v("First Name ")]),_vm._v(" "+_vm._s(_vm.customerRecord.firstName)+" ")]):_vm._e(),(_vm.customerRecord.lastName)?_c('v-col',{attrs:{"cols":"5"}},[_c('strong',[_vm._v("Last Name ")]),_vm._v(" "+_vm._s(_vm.customerRecord.lastName)+" ")]):_vm._e(),(false&&_vm.customerRecord.phoneNumber)?_c('v-col',{attrs:{"cols":"5"}},[_c('strong',[_vm._v("Phone Number ")]),_vm._v(" "),(_vm.customerRecord.preferedcontact===0)?_c('i',[_vm._v("(preferred)")]):_vm._e(),_vm._v(" "),_c('a',{staticStyle:{"text-decoration":"none !important"},attrs:{"href":['tel:' + _vm.customerRecord.phoneNumber]}},[_vm._v(_vm._s(_vm.customerRecord.phoneNumber))])]):_vm._e(),(false&&_vm.customerRecord.emailAddress)?_c('v-col',{attrs:{"cols":"5"}},[_c('strong',[_vm._v("Email Address")]),_vm._v(" "),(_vm.customerRecord.preferedcontact===1)?_c('i',[_vm._v("(preferred)")]):_vm._e(),_vm._v(" "),_c('a',{staticStyle:{"text-decoration":"none !important"},attrs:{"href":['mailto:' + _vm.customerRecord.emailAddress]}},[_vm._v(_vm._s(_vm.customerRecord.emailAddress))])]):_vm._e(),_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"indigo"},on:{"click":function($event){$event.stopPropagation();_vm.customerDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account ")])],1)]}}],null,false,2482733438)},[_c('span',[_vm._v("Edit Customer")])])],1)],1),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600px","persistent":""},model:{value:(_vm.customerDialog),callback:function ($$v) {_vm.customerDialog=$$v},expression:"customerDialog"}},[[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Customer Details")]),_c('customerDetails',{attrs:{"customerDetails":_vm.customerRecord}}),_c('v-toolbar',{attrs:{"dark":""},on:{"click":function($event){_vm.customerDialog = false}}},[_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.customerDialog = false}}},[_vm._v("Close")])],1)],1)],1)]],2)],1):_vm._e(),(_vm.customerRecord._id)?_c('div',[_c('quoteDetails',{attrs:{"quoteDetails":_vm.currentItem,"customerDetails":_vm.customerRecord,"showSchedule":true},on:{"updatedQuote":_vm.refreshQuotes}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<div>
		<div style="background-color:maroon;color:white">
			<div style="margin-left:5px;">
				Website Statistics
			</div>
		</div>
		<v-data-table
			:headers="headers"
			:items="items"
			single-select
			class="elevation-1"
			sortBy="page"
		>
		</v-data-table>			
		<v-textarea
			v-model="itemList"
			label="CSV stats"
		>
		</v-textarea>
	</div>
</template>

<script>
	import services from '@/services/services.js'

  export default {
    name: 'stats',
    data: () => ({
		items: [],
		item: {},
		itemList:"",
        headers: [
			{ text: 'Web Page', align: 'start', value: 'page' },
			{ text: 'Views', value: 'count', sortable: false }
        ],	
    }),
	methods: {
	},	
	created() {
		services.getStats().then(response=>{
			this.items=response.data.data;
			
			if (response.data.data)
			{
				var stats="";
				
				response.data.data.forEach(function(item) {
					stats+=item.page+","+item.count+"\n";
				});
				this.itemList=stats;
			}
		});//.catch(error=> { console.log(error)})
	},
  }
</script>

<template>
	<v-container>
		<div>
			<h2 v-if="!authenticated">Login</h2>
			<form @submit="checkCredentials" v-on:submit.prevent>
				<div v-if="!authenticated">
					<v-text-field prepend-icon="mdi-account" label="Username" type="text" v-model="username" name="username" />
				</div>
				<div v-if="!authenticated">
					<v-text-field prepend-icon="mdi-lock" label="Password" type="password" v-model="password" name="password" />
				</div>
				<div v-if="!authenticated">
					<v-btn class="btn btn-primary" type="submit" :style="btnStyle">Login</v-btn>
				</div>
				<div v-if="authenticated">
					<v-btn class="btn btn-primary" @click="logout">Logout</v-btn>
				</div>
			</form>
		</div>
	</v-container>
</template>

<script>
	import services from '@/services/services.js'

  export default {
    name: 'residential',
    data: () => ({
		carPricing:[],
		homeOfficePricing:[],
		username:'',
		password:'',
		btnStyle:''
		
    }),
	methods: {
		randomItem () {
			services.getPricing().then(response=>{console.log(response.data.openpay);this.carPricing=response.data.car;this.homeOfficePricing=response.data.homeOffice}).catch(error=> { console.log(error)})
		},
		checkCredentials() {
			//console.log(this.username);
			//console.log(this.password);
			var loginData={data:{username:this.username,password:this.password}};
			services.checkAccess(loginData)
			.then(response=>{
				//console.log(response.data);
				if (response.data.result==="Authenticated") 
				{
					//console.log(response.data);
					//console.log(this.$store.state);
					this.$store.commit('setAccessLevel',response.data.accessLevel);					
					this.$store.commit('setToken',response.data.token);					
					this.$store.commit('setUser',response.data.userid);					
					this.$store.commit('login');					
					this.$router.replace("/sgm/solartintpenrith/admin/schedule");
				}
				else
				{
					console.log("Set button style");
					this.btnStyle="background-color: red;";
				}
			})
			.catch(error=> { console.log("Error!! "+error);this.btnStyle="background-color: red;";});
		},
		logout() {
			this.$store.state.accessLevel=0
			this.$store.commit('setAccessLevel',0);					
			this.$store.commit('logout');					
		}
	},	
	computed: {
		admin() {
			return this.$store.state.admin;
		},
		authenticated() {
			return this.$store.state.authenticated;
		}	
	},
  }
</script>

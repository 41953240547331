<template>
	<v-container>
		<img style="width:20%" src="/wheel1.webp" alt="">  
		<img style="width:20%" src="/wheel2.webp" alt="">
		<img style="width:20%" src="/wheel3.webp" alt="">
		<img style="width:20%" src="/wheel4.webp" alt="">
		<img style="width:20%" src="/wheel5.webp" alt="">
		<div  style="float:inline">
			<div>
				Since 2007 we have owned and operated a Fleet of Toyota's that lead us to stocking and selling Genuine Factory Toyota wheels. 
			</div>
			<div>
				As we've got to know Toyota's well over the years, we also have 4x4 Commercial vehicle dealerships specialising in Toyota's.			
			</div>
			<v-row class="md-12">
				<v-col cols=4>
					<v-btn color="green" style="margin-top:15px;width:100%;" href="https://www.ebay.com.au/str/sgmtoyota4x4wheels">
						Store
					</v-btn>			
				</v-col>
			</v-row>
		</div>	
	</v-container>
</template>

<script>
	import services from '@/services/services.js'

  export default {
    name: 'wheels',
    data: () => ({
		items: [
			'/wheels1.webp',
			'/wheels2.webp',
			'/wheels3.webp',
			'/wheels4.webp',
			'/wheels5.webp',
		],
		selectedImageCar: null,
		selectedImageOther: null
    }),
	methods: {
		randomItem (items) {
			return items[Math.floor(Math.random()*items.length)];
		}
	},	
	created() {
		var viewdata={page:"Wheels","count":1};
		services.updateStats(viewdata);
		this.selectedImageCar = this.randomItem(this.items);
		this.selectedImageCar2 = this.selectedImageCar;
		this.selectedImageCar3 = this.selectedImageCar;
		while (this.selectedImageCar===this.selectedImageCar2)
			this.selectedImageCar2 = this.randomItem(this.items);
		while (this.selectedImageCar===this.selectedImageCar3||this.selectedImageCar2==this.selectedImageCar3)
			this.selectedImageCar3 = this.randomItem(this.items);			
	}
  }
</script>

<template>
	<v-container>
		<img v-if="selectedImageCar" style="width:33%" :src="selectedImageCar" alt="">  
		<img v-if="selectedImageCar2" style="width:33%" :src="selectedImageCar2" alt="">
		<img v-if="selectedImageCar3" style="width:33%" :src="selectedImageCar3" alt="">
		<div  style="float:inline">
			<h1>Residential Window Tinting</h1>				
			There are many advantages to tinting your home windows:
			<ul>
				<li>Block damaging and dangerous UV rays</li>
				<li>Reduce heat in summer and trap it in winter</li>
				<li>Eliminate glare from your eyes and screens</li>
				<li>Privacy</li>
				<li>Improved safety and security</li>
				<li>Energy savings reducing the cost of heating or cooling your home.</li>
			</ul>
			<v-row class="md-12">
				<v-col cols=4>
					<v-btn color="green" style="margin-top:15px;width:100%;" href="/quote">
						Get a Quote
					</v-btn>			
				</v-col>
			</v-row>
		</div>	
	</v-container>
</template>

<script>
	import services from '@/services/services.js'
	
  export default {
    name: 'residential',
    data: () => ({
		items: [
			'/home-2.jpg',
			'/home-3.jpg',
			'/home-4.jpg',
			'/home-5.jpg',
			'/home-6.jpg',
		],
		selectedImageCar: null,
		selectedImageOther: null
    }),
	methods: {
		randomItem (items) {
			return items[Math.floor(Math.random()*items.length)];
		}
	},	
	created() {
		var viewdata={page:"Residential","count":1};
		services.updateStats(viewdata) 
		this.selectedImageCar = this.randomItem(this.items);
		this.selectedImageCar2 = this.selectedImageCar;
		this.selectedImageCar3 = this.selectedImageCar;
		while (this.selectedImageCar===this.selectedImageCar2)
			this.selectedImageCar2 = this.randomItem(this.items);
		while (this.selectedImageCar===this.selectedImageCar3||this.selectedImageCar2==this.selectedImageCar3)
			this.selectedImageCar3 = this.randomItem(this.items);			
	}
  }
</script>

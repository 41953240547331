import axios from 'axios'

const apiClient=axios.create({
	baseURL: 'https://test.iotx3.com',
	withCredentials: false,
	headers:{
	}
})

export default {
	getSuburbs(postcode) {
		var postcodeJson={postcode:postcode};
		return apiClient.post("/admin/tint/suburbs",postcodeJson)
	},
	getPricing() {
		return apiClient.get("/admin/tint/loadPricing")
	},
	getInventory() {
		return apiClient.get("/admin/tint/loadInventory")
	},	
	getStats() {
		return apiClient.get("/admin/tint/stats")
	},	
	getCustomersFiltered(filter) {
		return apiClient.post("/admin/tint/customerfilter",filter)
	},	
	getTintQuoting() {
		return apiClient.get("/admin/tint/quotingsummary")		
	},
	getSchedule(filter) {
		return apiClient.post("/admin/tint/schedule",filter)
	},	
	postPricing(pricing) {
		return apiClient.post("/admin/tint/savePricing",pricing)
	},	
	saveQuote(quoteData) {
		return apiClient.post("/admin/tint/saveQuote",quoteData)
	},
	checkAccess(logindata) {
		return apiClient.post("/admin/tint/access",logindata)
	},
	updateInventory(inventoryData) {
		return apiClient.post("/admin/tint/saveInventory",inventoryData)
	},
	updateStats(viewData) {
		return apiClient.post("/admin/tint/webviews",viewData)
	},
	
	/* Token secured */
	//Customer
	updateCustomer(config) 
	{
		return apiClient.post("/admin/tint/updateCustomer",config)
	},
	getCustomer(data) {
		return apiClient.post("/admin/tint/customer",data)
	},
	getCustomers(config) {
		return apiClient.post("/admin/tint/customers",config)
	},
	getCustomerQuotes(filter)
	{
		return apiClient.post("/admin/tint/customerQuotes",filter)
	},
	
	//Generic collections
	getSingleCollectionItem(config)
	{
		return apiClient.post("/admin/api/tinting/item/collection",config)
	},
	updateCollectionItem(config)
	{
		return apiClient.post("/admin/api/tinting/item/updatecollection",config)
	},
	
	//Quoting
	getTintQuotingDetails(config) {
		return apiClient.post("/admin/tint/quoting",config)		
	},	
	postQuoteConfig(config) {
		return apiClient.post("/admin/tint/saveQuoteConfig",config)
	},
	updateQuote(config) {
		return apiClient.post("/admin/tint/updateQuote",config)
	},
	getQuotes(config) {
		return apiClient.post("/admin/tint/getQuotes",config)
	},	
	getFilteredQuotes(filter)
	{
		return apiClient.post("/admin/tint/getFilteredQuotes",filter)
	},
	
	//users
	getUsers(config) {
		return apiClient.post("/admin/tint/users",config);
	},
	updateUser(config) {
		return apiClient.post("/admin/tint/updateUser",config);
	},
	updatePassword(config) {
		return apiClient.post("/admin/tint/updatePassword",config);
	},
	
	//PDF
	generatePDF(settings)
	{
		return apiClient.post("/system/pdf",settings)
	},
	//PDF
	generatePDF2(settings)
	{
		console.log(settings);
		return apiClient.get("/pdf")
	},	
	
	//schedule
	//getSchedule(config) {
	//	return apiClient.post("/admin/tint/schedule",config);
	//},
	updateSchedule(config) {
		return apiClient.post("/admin/tint/updateschedule",config);
	},	
	deleteSchedule(config) {
		return apiClient.post("/admin/tint/deleteschedule",config);
	},
	//axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
	//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

	getSchedules(range)
	{
		return apiClient.post('/admin/tint/get/schedule',range);
	},
	uploadImage(imageFile)
	{
//		let formData = new FormData();
//		formData.append("image",imageFile);
		var config={
			headers: 
			{
				'Content-Type': 'multipart/form-data'
			}
		};
		return apiClient.post("/tint/upload",imageFile,config)
		// upload to server with axios
//						axios.post(BaseURL + '/api/uploadAdImage', formData,         {
//							headers: {
//								'Content-Type': 'multipart/form-data',
//								}
//						});		
	},
	deleteImage(imageFile)
	{
		var config={
			headers: 
			{
				'name': imageFile
			}
		};
		return apiClient.delete("/tint/delete/image",{},config)		
	}
}
<template>
	<v-container>
		<v-container>
			<img style="width:33%" :src="selectedImageCar3" alt="">  
			<img v-if="selectedImageCar" style="width:33%" :src="selectedImageCar" alt="">  
			<img v-if="selectedImageCar2" style="width:33%" :src="selectedImageCar2" alt="">
		</v-container>
		<v-container>
			<h1 class="font-italic font-bold font-weight-black" style="color:red;">
				The experts in all things Window Tinting
			</h1>	
			<div class="font-bold">Since 2000, our talented team works hard everyday to provide the best window tinting services for our customers.</div>
			<div>We customise our offerings based on specific needs, so get in touch today to receive an obligation-free quote!</div>
			
			<h2 style="margin-top:15px">About us</h2>
			<div>Since our founding, Solartint Penrith and Windsor has been known for quality services, exceptional efficiency and the highest level of professionalism.</div>
			<div>No matter what you’re looking for, we guarantee to not only meet, but exceed your expectations and ensure your full satisfaction as you will see with our countless testimonials.</div>
			<div>Our team is up for every job, managing projects with the skill and experience our customers have come to expect.</div>
			<v-row class="md-12">
				<v-col cols=4>
					<v-btn color="green" style="margin-top:15px;width:100%;" href="/quote">
						Get a Quote
					</v-btn>			
				</v-col>
			</v-row>
			
			<div style="margin-top:15px">
				<h1 class="font-italic font-bold font-weight-black" style="display:inline">
					Professional Services
				</h1>		
				<h3 class="font-italic" style="display:inline">  What we do</h3>				
			</div>
			<div style="margin-top:15px">
				<h3>Residential Window Tinting</h3>				
				<img style="width:15%;float:right" v-if="selectedImageHome" :src="selectedImageHome">  
				<img style="width:15%;float:right" v-if="selectedImageHome2" :src="selectedImageHome2">  
				<div  style="float:inline">
					There are many advantages to tinting your windows:
					<ul>
						<li>Block damaging and dangerous UV rays</li>
						<li>Reduce heat in summer and trap it in winter</li>
						<li>Eliminate glare from your eyes and screens</li>
						<li>Privacy</li>
						<li>Improved safety and security</li>
					</ul>
				</div>
			</div>
			<div style="margin-top:15px">
				<h3>Commercial Window Tinting</h3>				
				<img style="width:15%;float:right" v-if="selectedImageCom" :src="selectedImageCom">  
				<img style="width:15%;float:right" v-if="selectedImageCom2" :src="selectedImageCom2">  
				<div  style="float:inline">
					We can tint any office or building.
				</div>
				<div  style="float:inline">
					There are many advantages to tinting your windows:
					<ul>
						<li>Block damaging and dangerous UV rays</li>
						<li>Reduce heat in summer and trap it in winter</li>
						<li>Eliminate glare from your eyes and screens</li>
						<li>Privacy</li>
						<li>Improved safety and security</li>
					</ul>
				</div>
				<div  style="float:inline">
					Since its founding, Solartint Penrith and Windsor has been one of the most trusted names in the industry. 
				</div>
			</div>
		</v-container>
	</v-container>
</template>

<script>
	import services from '@/services/services.js'

  export default {
    name: 'about',
    data: () => ({
		items: [
			'/home-2.jpg',
			'/home-3.jpg',
			'/home-4.jpg',
			'/home-5.jpg',
			'/home-6.jpg',
			'/commercial-1.jpeg',
			'/commercial-2.jpeg',
			'/commercial-3.jpeg',
			'/commercial-4.jpeg',
			'/commercial-5.jpeg',
			'/commercial-6.jpeg',			
		],
		home: [
			'/home-2.jpg',
			'/home-3.jpg',
			'/home-4.jpg',
			'/home-5.jpg',
			'/home-6.jpg',
		],
		selectedImageCar: null,
		selectedImageCar2: null,
		selectedImageCar3: null,
		selectedImageCar4: null,
		selectedImageCar5: null,
		selectedImageOther: null,
		selectedImageHome: null,
		selectedImageHome2: null,
		selectedImageCom: null,
		selectedImageCom2: null,
    }),
	methods: {
		randomItem (items) {
			return items[Math.floor(Math.random()*items.length)];
		}
	},	
	created() {
		var viewdata={page:"About","count":1};
		services.updateStats(viewdata);
		this.selectedImageCar = this.randomItem(this.items);
		this.selectedImageCar2 =this.randomItem(this.items);
		this.selectedImageCar3 =this.randomItem(this.items);
		while (this.selectedImageCar===this.selectedImageCar2)
			this.selectedImageCar2 = this.randomItem(this.items);
		while (this.selectedImageCar===this.selectedImageCar3)
			this.selectedImageCar3 = this.randomItem(this.items);			
		this.selectedImageCar4 = this.randomItem(this.items);
		this.selectedImageCar5 = this.selectedImageCar4;
		while (this.selectedImageCar4===this.selectedImageCar5)
			this.selectedImageCar5 = this.randomItem(this.items);			
		this.selectedImageHome = this.randomItem(this.home);
		this.selectedImageHome2 = this.selectedImageHome;
		while (this.selectedImageHome===this.selectedImageHome2)
			this.selectedImageHome2 = this.randomItem(this.items);
		this.selectedImageCom = this.randomItem(this.commercial);
		this.selectedImageCom2 = this.selectedImageCom;
		while (this.selectedImageCom===this.selectedImageCom2)
			this.selectedImageCom2 = this.randomItem(this.items);			
	}
  }
</script>

<template>
	<v-container>
		<div style="background-color:maroon;color:white">
			Quote Settings
		</div>
		<v-text-field
			v-model="search"
			append-icon="mdi-magnify"
			label="Search"
			single-line
			hide-details
			style="margin-bottom: 5px;"
		></v-text-field>
		<v-data-table
			v-model="selectedItem"
			:headers="headers"
			:items="data"
			:item-class="itemRowBackground"			
			item-key="_id"
			single-select
			class="elevation-1"
			@click:row="editItem"
			:search="search"
		>
		<template v-slot:item.accesslevel="{ item }">
			{{ displayAccessLevel(item.accesslevel) }}
		</template>		
		<template v-slot:item.actions="{ item }">
			<v-icon
				small
				class="mr-2"
				@click="editItem(item)"
			>
				mdi-pencil
			</v-icon>
			<v-icon
				v-if="item._id!==currentUserID"
				small
				@click="deleteItem(item)"
			>
				mdi-delete
			</v-icon>
		</template>		
		</v-data-table>	
		<v-container v-if="currentData.label">				
			<div style="background-color:maroon;color:white; margin-top:15px;">
				Details
			</div>	
			<div>
			<form @submit.prevent="submit">
				<v-row>
					<v-switch v-if="currentData.deleted"
						v-model="currentData.deleted"
						label="Deleted"
						style="margin-left:15px"
					></v-switch>	
					<v-switch v-if="!currentData.deleted"
						v-model="currentData.deleted"
						label="Mark Deleted"
						style="margin-left:15px"
					></v-switch>	

				</v-row>	
				<v-row>
					<v-col cols=5>
						<v-text-field
							v-model="currentData.label"
							:counter="40"
							label="Name"
						></v-text-field>
					</v-col>						
					<v-col cols=5>
						<v-text-field
							v-model="currentData.image"
							:counter="40"
							label="Image Path"
						></v-text-field>
					</v-col>						
					<v-col cols=2>
						<v-img
							alt="Vuetify Logo"
							contain
							:src="currentData.image"
							transition="scale-transition"
							width="80px"
							style="margin-top:15px"
						/>
					</v-col>						
				</v-row>	
				<v-row>
					<v-col cols=3>
						<v-select
							v-model="currentData.installtype"
							:counter="100"
							:items="installTypes"
							label="Install type"
							required
						></v-select>				
					</v-col>						
					<v-col cols=3 v-if="currentData.installtype==='Building'">
						<v-text-field
							v-model="currentData.pricepermetre"
							type="number"
							label="Price per square metre"
						></v-text-field>
					</v-col>
					<v-col cols=3 v-if="currentData.installtype==='Building'">
						<v-text-field
							v-model="currentData.tintremoval"
							type="number"
							label="Removal Price per square metre"
						></v-text-field>
					</v-col>						
					<v-col cols=3 v-if="currentData.installtype==='Automotive'">
						<v-text-field
							v-model="currentData.sidewindows"
							type="number"
							label="Side Windows"
						></v-text-field>
					</v-col>						
					<v-col cols=3 v-if="currentData.installtype==='Automotive'">
						<v-text-field
							v-model="currentData.smallsections"
							type="number"
							label="Small Window Sections"
						></v-text-field>
					</v-col>	
					<v-col cols=3>
						<v-text-field
							v-model="currentData.minimumprice"
							type="number"
							label="Minimum price"
						></v-text-field>
					</v-col>						
				</v-row>	
				<v-row v-if="currentData.installtype==='Automotive'">
					<v-col cols=3>
						<v-text-field
							v-model="currentData.price"
							type="number"
							label="Price"
						></v-text-field>
					</v-col>						
					<v-col cols=3>
						<v-text-field
							v-model="currentData.tintremoval"
							type="number"
							label="Tint Removal Price"
						></v-text-field>
					</v-col>	
					<v-col cols=3>
						<v-text-field
							v-model="currentData.premium"
							type="number"
							label="Add Premium Price"
						></v-text-field>
					</v-col>	
					<v-col cols=3>
						<v-text-field
							v-model="currentData.offsite"
							type="number"
							label="Off site install Price"
						></v-text-field>
					</v-col>						
				</v-row>	
				<v-row v-if="currentData.installtype==='Automotive'">
					<v-col cols=3>
						<v-text-field
							v-model="currentData.extrawindows"
							type="number"
							label="Per Extra window price"
						></v-text-field>
					</v-col>						
					<v-col cols=3>
						<v-text-field
							v-model="currentData.extrasection"
							type="number"
							label="Per Extra section price"
						></v-text-field>
					</v-col>	
					<v-col cols=3>
						<v-text-field
							v-model="currentData.perwindowremoval"
							type="number"
							label="Per Window tint removal price"
						></v-text-field>
					</v-col>	
					<v-col cols=3>
						<v-text-field
							v-model="currentData.frontbackremoval"
							type="number"
							label="Per Front or back tint removal price"
						></v-text-field>
					</v-col>						
				</v-row>				
				<v-row>
					<v-col cols=3>
						<v-text-field
							v-model="currentData.row"
							type="number"
							label="Display row"
						></v-text-field>
					</v-col>						
				</v-row>				
				<v-row>
					<v-textarea
						v-model="currentData.style"
						label="Styling"
						style="margin-left:15px"
					></v-textarea>					
				</v-row>
				<v-row>
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="mx-2"
								fab
								dark
								color="indigo"
								v-bind="attrs"
								v-on="on"
								@click="saveData"
								style="margin-top:5px"
							>
								<v-icon dark>
									mdi-content-save
								</v-icon>
							</v-btn>
						</template>
						<span>Save changes</span>
					</v-tooltip>
				</v-row>				
			</form>
			</div>
		</v-container>
	</v-container>
</template>

<script>
	import services from '@/services/services.js'

  export default {
    name: 'quoteConfig',
    data: () => ({
		installTypes: ["Building","Automotive"],
		search:'',
		data:[],
		selectedItem:[],
		currentData:{},
		accessDetails:[{accessLevel:1,label:"User"},{accessLevel:2,label:"Administrator"}],
        headers: [
			{ text: 'Quote on', align: 'start', value: 'label' },
			{ text: 'Image', value: 'image', sortable: false },
			{ text: 'Type', value: 'installtype', sortable: false },
        ],	
    }),
	computed: {
		currentUserID() {
			return this.$store.state.user;
		},
		currentToken() {
			console.log(this.$store.state);
			return this.$store.state.token;
		},		
	},
	methods: {
		displayAccessLevel: (accessLevel) => {
			console.log("displayAccessLevel "+accessLevel);
			var result="User";
			var accessLevels=[{accessLevel:1,label:"User"},{accessLevel:2,label:"Administrator"}];
			console.log(accessLevels);
			if (accessLevels)
			{
				accessLevels.forEach(function(item){
					if (item.accessLevel===accessLevel)
					{
						result=item.label;
					}
				});
			}
			
			return result;
		},		
		itemRowBackground: function (item) {
			return item.deleted ? 'deletedItem' : ''
		},
		editItem(item){
			this.currentData=item;
		},
		deleteItem(item){
			this.currentData=item;
			item.deleted=true;
		},
		saveData()
		{
			var request={data:this.currentData,token:this.$store.state.token};
			services.postQuoteConfig(request).then(response=>{this.data=response.data.data;if (response.data.token) this.$store.commit('setToken',response.data.token);}).catch(error=> { console.log(error)})		
			this.currentData={};
		}
	},
	created() {
		var request={token:this.$store.state.token};
		services.getTintQuotingDetails(request).then(response=>{
			if (response.data.data)
			{
				this.data=response.data.data;
			}
			if (response.data.token) this.$store.commit('setToken',response.data.token);			
		}).catch(error=> { console.log(error)});	
	},
  }
</script>
<style>
	.deletedItem {
		text-decoration: line-through;
	}
</style>
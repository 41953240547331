import createPersistedState from 'vuex-persistedstate'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		admin:false,
		authenticated:false,
		accessLevel:0,
		user:'',
		token:'',
		showSolartint:true,
		requestQuotePressed:false
	},
	mutations: {
		login: state=>state.authenticated=true,
		logout: state=>state.authenticated=false,
		setAccessLevel(state, accessLevel) {
			state.accessLevel = accessLevel;
		},
		setUser(state, user) {
			state.user = user;
		},
		setToken(state, token) {
			state.token = token;
		},		
	},
	actions: {
	},
	modules: {
	},
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
		})
	],
})

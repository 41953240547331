<template>
	<v-container>
		<v-row>
			<v-btn style="margin-top:20px" @click="changeDate(-7)">&lt;&lt; </v-btn>
			<v-btn style="margin-top:20px; margin-bottom:20px;" @click="changeDate(+7)"> >></v-btn>
			<v-checkbox v-model="showWeekend" label="Weekends" style="margin-left:55px;margin-top:15px;">
			</v-checkbox>
            <div v-if="showWeekend" style="margin-left:55px;margin-top:20px;"><b>{{weekendText}}</b></div>
            <div v-else style="margin-left:55px;margin-top:20px;"><b>{{weekText}}</b></div>
		</v-row>
		<v-row v-if="customerRecord._id" style="margin-top:5px; margin-bottom:10px;margin-left:3px;">
			{{customerDetails}} 
		</v-row>
		<ejs-schedule width='100%' readonly="true" id="schedule" :selectedDate='selectedDate' :actionComplete="eventAction" :eventSettings='eventSettings' :showHeaderBar='showHeaderBar' :popupOpen='onPopupOpen' >
			<e-views>
				<e-view option='Day' :showWeekend="showWeekend" startHour='06:00' endHour='19:00'></e-view>
				<e-view option='Week' :showWeekend="showWeekend" startHour='06:00' endHour='19:00'></e-view>
				<e-view option='Month' :showWeekend="showWeekend"></e-view>
				<e-view option='Agenda' :showWeekend="showWeekend"></e-view>
			</e-views>
			<e-resources>
				<e-resource field="TypeId" title="Work Type" name="Types" :dataSource="typeDataSource"
					textField="TypeText" idField="Id"  colorField="TypeColor">
				</e-resource>
			</e-resources>
		</ejs-schedule>
	</v-container>
</template>

<script>
	import services from '@/services/services.js'
	import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, DragAndDrop, Resize, ViewsDirective, ViewDirective, ResourcesDirective, ResourceDirective } from "@syncfusion/ej2-vue-schedule";

  export default {
    name: 'scheduleDetails',
    props:['eventSettings','showDialog'],
	components: {
		'ejs-schedule': ScheduleComponent,
		'e-views': ViewsDirective,
		'e-view': ViewDirective,
		'e-resources': ResourcesDirective,
		'e-resource': ResourceDirective,
	},
    data: () => ({
        customerRecord:[],
        customerDetails:"",
        weekendText:"",
        weekText:"",
		showWeekend:false,
		showHeaderBar:false,
        selectedDate: new Date(),
		selectedWorkshops:[],
		workshopData: ['Seven Hills', 'Macksville'],
        typeDataSource: [
            { TypeText: 'Automotive', Id: 1, TypeColor: 'Red' },
            { TypeText: 'Residential', Id: 2, TypeColor: 'Blue' },
            { TypeText: 'Commercial', Id: 3, TypeColor: 'Green' },
            { TypeText: 'Selected', Id: 4, TypeColor: 'black' },
        ],
    }),
	provide: {
		schedule: [Day, Week, WorkWeek, Month, Agenda, DragAndDrop, Resize]
	},
	methods: {
        notused(){
            services.getTintQuoting().then(response=>{
                if (response.data.data)
                {
                    this.tintItems=response.data.data;
                }
            }).catch(error=> { console.log(error)});

            var config={token:this.$store.state.token};
            services.getQuotes(config).then(response=>{if (response.data.data) this.data=response.data.data;if (response.data.token && response.data.result==="ok") this.$store.commit('setToken',response.data.token); else this.$store.commit('setToken',"");}).catch(error=> { console.log(error)});
        },
        setDateRange()
        {
            var dow=new Date().getDay();
            var start=new Date(this.selectedDate.getFullYear(),this.selectedDate.getMonth(),this.selectedDate.getDate())-(dow)*24*60*60*1000;
            var endDate=new Date(new Date(start).getFullYear(),new Date(start).getMonth(),new Date(start).getDate())-1+8*24*60*60*1000;
            this.$emit("selectedDate",{start:start,end:endDate});
            this.weekendText=new Date(start).getFullYear()+"-"+(new Date(start).getMonth()+1)+"-"+new Date(start).getDate()+" to "+new Date(endDate).getFullYear()+"-"+(new Date(endDate).getMonth()+1)+"-"+new Date(endDate).getDate();
            start=start+24*60*60*1000;
            endDate=endDate-24*60*60*1000;
            this.weekText=new Date(start).getFullYear()+"-"+(new Date(start).getMonth()+1)+"-"+new Date(start).getDate()+" to "+new Date(endDate).getFullYear()+"-"+(new Date(endDate).getMonth()+1)+"-"+new Date(endDate).getDate();
						//scheduleObj.refresh();

        },
		changeDate(delta)
		{
			this.selectedDate=new Date(this.selectedDate.getTime()+delta*24*60*60*1000);
            this.setDateRange();
		},
		addSchedule()
		{
			this.currentData.Id=this.newId++;
			this.currentData.EventType="Requested";
			this.currentData.Subject="Test "+this.currentData.Id;
			this.dialog=false;
		},
		onPopupOpen: function(item) {
			this.customerDetails="";
			if (this.showDialog)
			{
				services.getCustomer({id:item.data.customerid,token:this.$store.state.token})
					.then(response=>{
							if (response.data.data) this.customerRecord=response.data.data;
							if (this.customerRecord.firstName) this.customerDetails+=this.customerRecord.firstName;
							if (this.customerRecord.lastName) this.customerDetails+=" "+this.customerRecord.lastName;
							if (this.customerRecord.preferedContact===0||!this.customerRecord.preferedContact)
							{
								if (this.customerRecord.phoneNumber) 
								{
									this.customerDetails+="   Phone: "+this.customerRecord.phoneNumber
									if (this.customerRecord.emailAddress) this.customerDetails+=" (preferred)";
								}
								if (this.customerRecord.emailAddress) this.customerDetails+="   Email: "+this.customerRecord.emailAddress;
							}
							else
							{
								if (this.customerRecord.phoneNumber) this.customerDetails+="   Phone: "+this.customerRecord.phoneNumber;
								if (this.customerRecord.emailAddress) this.customerDetails+="   Email: "+this.customerRecord.emailAddress+" (preferred)";

							}
							if (response.data.token && response.data.result==="ok") 
								this.$store.commit('setToken',response.data.token); 
							else 
								this.$store.commit('setToken',"");
						})
					.catch(error=> { console.log(error)});
			}
			item.cancel = true;
		},
		click(args) {
			console.log(args);
		},
		eventAction(args)
		{
			if (args.requestType==="eventChanged")
			{
				var newSource=[]
				this.eventSettings.dataSource.forEach(function(item)
				{
					if (item.Id==args.changedRecords[0].Id)
					{
						var newItem={};
						newItem.Id=args.changedRecords[0].Id;
						newItem.StartTime=args.changedRecords[0].StartTime;
						newItem.EndTime=args.changedRecords[0].EndTime;
						newItem.OwnerId=args.changedRecords[0].OwnerId;
						newItem.TypeId=args.changedRecords[0].TypeId;
						newItem.IsAllDay=args.changedRecords[0].IsAllDay;
						newItem.Subject=args.changedRecords[0].Subject;
						newSource.push(newItem)
					}
					else
						newSource.push(item);
				});
				this.eventSettings.dataSource=newSource;
				let scheduleObj = document.getElementById("schedule").ej2_instances[0];
				scheduleObj.eventSettings.dataSource = this.eventSettings.dataSource
			}
		},
	},	
	computed: {
	},
	created() {
        this.setDateRange();
	},
  }
</script>
<style>
	@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
	@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
	@import "../../node_modules/@syncfusion/ej2-vue-buttons/styles/material.css";
	@import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
	@import '../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css';
	@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
	@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
	@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
	@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
	@import '../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';
	</style>
<template>
	<v-container>
		<div style="background-color:maroon;color:white">
			Change Password
		</div>
		<form @submit.prevent="submit">
			<v-row>
				<v-col>
					<v-text-field
						v-model="currentPassword"
						:counter="40"
						label="Current Password"
						type="password"
					></v-text-field>
				</v-col>						
				<v-col>
					<v-text-field
						v-model="newPassword"
						:counter="40"
						label="New Password"
						type="password"
					></v-text-field>
				</v-col>						
			</v-row>	
		</form>
		<v-row>
			<v-tooltip top 	v-if="currentUser._id!==currentUserID">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						class="mx-2"
						fab
						dark
						:color="buttonColour"
						v-bind="attrs"
						v-on="on"
						@click="saveData"
						style="margin-top:5px"
					>
						<v-icon dark>
							mdi-content-save
						</v-icon>
					</v-btn>
				</template>
				<span>Change Password</span>
			</v-tooltip>
		</v-row>
		<div>
			<div v-if="currentUser._id===currentUserID" style="margin-top:15px">
				The current user cannot be updated please login as a different user to update the current user.  The password can be changed for the current user.
			</div>
		</div>
	</v-container>
</template>

<script>
	import services from '@/services/services.js'

  export default {
    name: 'residential',
    data: () => ({
		currentUser:{},
		currentPassword:'',
		newPassword:'',
		buttonColour:'indigo'
    }),
	computed: {
		currentUserID() {
			return this.$store.state.user;
		},
		currentToken() {
			return this.$store.state.token;
		},		
	},
	methods: {
		saveData()
		{
			var request={data:this.currentUser,token:this.$store.state.token,password:this.currentPassword,newPassword:this.newPassword};
			services.updatePassword(request).then(response=>{if (response.data.token && response.data.result==="ok") this.$store.commit('setToken',response.data.token); if (response.data.result==="ok") {this.currentPassword=''; this.newPassword='';} else this.buttonColour="red";}).catch(error=> { console.log(error); this.buttonColour="red"})
		}
	},
	created() {
		this.currentUser=this.$store.state.user;
	},
  }
</script>
<style>
	.deletedItem {
		text-decoration: line-through;
	}
</style>
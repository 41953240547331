<template>
    <v-container>
		<div style="background-color:maroon;color:white">
			Schedule
		</div>
        <scheduleDetails @selectedDate="dateRangeChange" :eventSettings="eventSettings" :showDialog="true"></scheduleDetails>	
    </v-container>
</template>

<script>
	import scheduleDetails from '@/components/scheduleDetails.vue'
	import services from '@/services/services.js'

    export default {
    name: 'schedule',
	components: {
		scheduleDetails,
	},
    data: () => ({
        eventSettings: {
				dataSource: [],
			},
		newEventData:[],
		typeID:1,
    }),
	methods: {
		dateRangeChange(value)
		{
			this.lastValue=value;
			value.token=this.$store.state.token;
			services.getSchedules(value).then(response=>{
					if (response.data.token) 
						this.$store.commit('setToken',response.data.token);
					var newSchedule=[];
					if (response.data.data)
					{
						var id=1;
						response.data.data.forEach(function(item)
						{
							var newItem={};
							newItem.EndTime=new Date(item.EndTime);
							newItem.Id=id++;
							newItem.StartTime=new Date(item.StartTime);
							newItem.Subject=item.Subject;
							newItem.TypeId=item.TypeId;
							newItem.customerid=item.customerid;
							newSchedule.push(newItem);
						});
						this.updateSchedule(newSchedule);
					}
					
				}).catch(error=>{ console.log(error)})
        },
		updateSchedule(newSchedule)
		{
			this.eventSettings.dataSource=[];
			this.eventSettings.dataSource=newSchedule;
			var scheduleObj=document.getElementById('schedule').ej2_instances[0];
			scheduleObj.eventSettings.dataSource = this.eventSettings.dataSource;
			scheduleObj.refreshEvents();
		},
	},	
	computed: {
	},
	created() {
	},
  }
</script>
<style>
</style>
<template>
	<v-container>
		<div style="background-color:maroon;color:white">
			Inventory
		</div>
		<v-text-field
			v-model="search"
			append-icon="mdi-magnify"
			label="Search"
			single-line
			hide-details
			style="margin-bottom: 5px;"
		></v-text-field>
		<v-data-table
			v-model="selected"
			:headers="headers"
			:items="inventory"
			item-key="_id"
			single-select
			class="elevation-1"
			:search="search"
			@click:row="editItem"
			>
		<template v-slot:item.actions="{ item }">
			<v-icon
				small
				class="mr-2"
				@click="editItem"
			>
				mdi-pencil
			</v-icon>
			<v-icon
				v-if="item.available"
				small
				@click="deleteItem(item)"
			>
				mdi-delete
			</v-icon>
		</template>		
		</v-data-table>		
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					class="mx-2"
					fab
					dark
					color="indigo"
					v-bind="attrs"
					v-on="on"
					@click="newInventory"
					style="margin-top:10px"
				>
					<v-icon dark>
						mdi-plus
					</v-icon>
				</v-btn>
			</template>
			<span>Add inventory item</span>
		</v-tooltip>		
		<v-container v-if="id!=''">		
			<div style="background-color:maroon;color:white; margin-top:15px;">
				Details
			</div>	
			<form @submit.prevent="submit">
				<v-row>
					<v-switch
						v-model="available"
						label="Available"
						style="margin-left:15px"
					></v-switch>	
				</v-row>
				<v-row>
					<v-col cols=3>
						<v-text-field
							v-model="productCode"
							:counter="40"
							label="Product Code"
						></v-text-field>
					</v-col>						
					<v-col cols=9>
						<v-text-field
							v-model="productName"
							:counter="120"
							label="Product Name"
						></v-text-field>
					</v-col>						
				</v-row>	
				<v-row>
					<v-col cols=3>
						<v-text-field
							v-model="supplierProductCode"
							:counter="40"
							label="Supplier Product Code"
						></v-text-field>
					</v-col>						
					<v-col cols=9>
						<v-text-field
							v-model="supplierProductName"
							:counter="120"
							label="Supplier Product Name"
						></v-text-field>
					</v-col>						
				</v-row>		
				<v-row>
					<v-col cols=6>
						<v-text-field
							v-model="quickQuoteName"
							:counter="120"
							label="Quick quote Name"
						></v-text-field>
					</v-col>				
					<v-col cols=3>
						<v-text-field
							prepend-icon="mdi-currency-usd"						
							v-model="costPrice"
							type="number"
							label="Cost Price (ex GST)"
						></v-text-field>
					</v-col>						
					<v-col cols=3>
						<v-text-field
							v-model="price"
							prepend-icon="mdi-currency-usd"						
							type="number"
							label="Price (ex GST)"
						></v-text-field>
					</v-col>						
				</v-row>		
				<v-row>
					<v-col cols=3>
						<v-text-field
							v-model="rolls"
							type="number"
							label="Rolls in stock"
						></v-text-field>
					</v-col>						
					<v-col cols=3>
						<v-text-field
							v-model="rollsize"
							type="number"
							label="Roll size (m)"
						></v-text-field>
					</v-col>						
				</v-row>					
			</form>
			<v-tooltip top>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						class="mx-2"
						fab
						dark
						color="indigo"
						v-bind="attrs"
						v-on="on"
						@click="save"
					>
						<v-icon dark>	
							mdi-content-save
						</v-icon>
					</v-btn>
				</template>
				<span>Save changes</span>
			</v-tooltip>			
		</v-container>
	</v-container>
</template>

<script>
	import services from '@/services/services.js'

  export default {
    name: 'residential',
    data: () => ({
		available: true,
		id:'',
		productCode:'',
		productName:'',
		supplierProductName:'',
		supplierProductCode:'',
		quickQuoteName:'',
		price:0,
		costPrice:0,
		rolls:0,
		rollsize:0,
		errorMessage:'',
		search:'',
		inventory:[],
		selected:[],
        headers: [
			{ text: 'Product Code', align: 'start', sortable: false, value: 'productCode' },
			{ text: 'Product Name', value: 'productName' },
			{ text: 'Quick Quote Name', value: 'quickQuoteName' },
			{ text: 'Supplier', value: 'supplierProductName' },
			{ text: 'Supplier Code', value: 'supplierProductCode' },
			{ text: 'Actions', value: 'actions', sortable: false }
        ],
    }),
	methods: {
		randomItem (items) {
			return items[Math.floor(Math.random()*items.length)];
		},
		newInventory() {
			this.available=true;
			this.id='0';
			this.productCode='';
			this.productName='';
			this.supplierProductName='';
			this.supplierProductCode='';
			this.quickQuoteName='';
			this.price=9999;
			this.costPrice=9999;
			this.rolls=0;
			this.rollsize=0;
			this.errorMessage='';
		},
		save() {
			if (this.quickQuoteName!=="")
			{
				var product={};
				if (this.id!=="0")
					product._id=this.id;
				product.available=this.available;
				product.productCode=this.productCode;
				product.productName=this.productName;
				product.supplierProductName=this.supplierProductName;
				product.supplierProductCode=this.supplierProductCode;
				product.quickQuoteName=this.quickQuoteName;
				product.price=this.price;
				product.costPrice=this.costPrice;
				product.rolls=this.rolls;
				product.rollsize=this.rollsize;
				services.updateInventory(product).then(response=>{if (response.data.result=="ok") {this.id='';this.inventory=response.data.inventory;}}).catch(error=> { console.log(error)})
			}
			else
			{
				this.errorMessage="Quick Quote Name Required, not saved.";
			}
				
		},
		editItem(item)
		{
			this.id=item._id;
			this.available=item.available;
			this.productCode=item.productCode;
			this.productName=item.productName;
			this.supplierProductName=item.supplierProductName;
			this.supplierProductCode=item.supplierProductCode;
			this.quickQuoteName=item.quickQuoteName;
			this.price=item.price;
			this.costPrice=item.costPrice;
			this.rolls=item.rolls;
			this.rollsize=item.rollsize;
		},
		deleteItem(item)
		{
			item.available=false;
		}
	},	
	created() {
		services.getInventory().then(response=>{if (response.data.inventory) this.inventory=response.data.inventory;}).catch(error=> { console.log(error)})
	},
  }
</script>

<template>
	<v-container>
		<div style="background-color:#8B0000;color:white; margin-top:15px;padding-left:15px;">
			Transport
		</div>
		<img style="width:40%;margin-top:5px;" src="/sgmTransport1.jpg" alt="">  
		<div  style="float:inline">
			<div>
				Reliable & Quality Transport for
			</div>
			<ul>
				<li>Cars</li>
				<li>Boats</li>
				<li>Motorcycles</li>
				<li>Trailers</li>
				<li>Caravans</li>
				<li>Machinery</li>
				<li>Other specialised goods</li>
			</ul>
		</div>	
		<v-row class="cssa mb4-l cf" style="margin-top:30px; margin-left:10px;">
			<article class="fl w-50 dib-ns w-auto-ns mr4-m mr5-l mb4 pr2 pr0-ns">
				<h4 class="f5 f4-l fw6" style="margin-bottom: 0px;color:#8B0000">SGM Transport</h4>
				<div class="f7 f6-l db black-70">PO Box 3082</div>
				<div class="f7 f6-l black-70">Dural, NSW 2158</div>
				<div>
					<a class="f7 db pv3 black-70 link dim" style="padding-bottom: 0" href="mailto:transport@sgmcars.com.au">transport&#64;&#115;&#103;&#109;&#99;&#97;&#114;&#115;&#46;&#99;&#111;&#109;&#46;&#97;&#117;</a>
				</div>
				<a class="f6 db fw6 pv3 black-70 link dim" style="padding-bottom:5px;" title="Call SGM Transport" href="tel:0432425542">0432 242 542</a>
				<div>
					<a href="https://www.facebook.com/people/SGM-Transport/100087394564741/"><img src="facebook.svg" alt="SGM Transport Facebook" style="width:20px;"></a>
				</div>
			</article>	
			<article class="fl w-50 dib-ns w-auto-ns mr4-m mr5-l mb4 pr2 pr0-ns" style="margin-left:35px;">
				<h4 class="f5 f4-l fw6" style="margin-bottom: 0.5rem;color:#8B0000">Australia Wide</h4>
				<img src="SGM Transport.png" alt="Australia wide transport with SGM" style="width:150px;">
			</article>	
		</v-row>

</v-container>
</template>

<script>
	import services from '@/services/services.js'

  export default {
    name: 'wheels',
    data: () => ({
		selectedImageCar: null,
		selectedImageOther: null,
		email:'transport&#64;&#115;&#103;&#109;&#99;&#97;&#114;&#115;&#46;&#99;&#111;&#109;&#46;&#97;&#117;'
    }),
	methods: {
	},	
	created() {
		var viewdata={page:"Transport","count":1};
		services.updateStats(viewdata);
	}
  }
</script>
